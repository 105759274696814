import React from "react";
import globalHook from "./UseGlobalHook";

const initialState = {
  env: 'demo',
  inboxCount: 0,
  account_id: null,
  clouds: [],
  cloudAccounts: [],
  roles: [],
  roles_v2: [],
  policies: [],
  cloud_type_id: null,
  date: null,
  pageNavTitle: "",
  pageBackTitle: "",
  notifications: {
    list: [],
    visible: false,
  },
  alert: {
    className: "",
    content: "",
    color: "",
    visible: false
  },
  blade: {
    title: "",
    content: "",
    visible: false
  },
  contentBlade: {
    title: "",
    content: "",
    visible: false,
    side: "",
    style: null,
  },
  authenticated: false,
  userInfo: null,
  organization: {},
  organizations: [],
  integrations: [],
  portalCustomerMeta: {},
  reload: 0,
  integrationsLoaded: false,
  resourceProperties: {},
  superAdmin: false,
  hasPortalAccess: false,
  sessionTimeoutTime: null,
  cache: {
    connectriaTicketCC: {
      loading: false,
      data: []
    },
    connectriaTicketCategories: {
      loading: false,
      data: []
    },
    connectriaTicketPreferences: {
      loading: false,
      data: []
    },
    connectriaTicketDevices: {
      loading: false,
      data: []
    },
    costSavings: {
      loading: true,
      data: []
    }
  },
  commserveTimeRange: null,
  
};

const stateActions = {
  setCache: (store, cache) => {
    let updatedState = store.state.cache;
    updatedState[cache.key] = {loading: cache.loading || false, data: cache.data}
    store.setState({cache: updatedState});
  },
  setPageNavTitle: (store, newTitle) => {
    store.setState({pageNavTitle: newTitle});
  },
  
  setIntegrationsLoaded: (store, integrationsLoaded) => {
    store.setState({integrationsLoaded: integrationsLoaded});
  },
  setPageBackTitle: (store, newTitle) => {
    store.setState({pageBackTitle: newTitle});
  },
  setRoles: (store, roles) => {
    store.setState({roles: roles});
  },
  setRoles_v2: (store, roles) => {
    store.setState({roles_v2: roles});
  },
  setPolicies: (store, policies) => {
    store.setState({policies: policies});
  },
  setClouds: (store, clouds) => {
    store.setState({clouds: clouds});
  },
  setCloudAccounts: (store, accounts) => {
    store.setState({cloudAccounts: accounts});
  },
  setInboxCount: (store, newCount) => {
    store.setState({inboxCount: newCount});
  },
  setNotificationsList: (store, list) => {
    store.setState({ notifications: { ...store.state.notifications, list } });
  },
  setNotificationsVisible: (store, visible) => {
    store.setState({ notifications: { ...store.state.notifications, visible } });
  },
  setBlade: (store, newBladeState) => {
    store.setState({blade: newBladeState})
  },
  setContentBlade: (store, newContentBladeState) => {
    store.setState({contentBlade: newContentBladeState})
  },
  setAlert: (store, newAlertState) => {
    store.setState({alert: newAlertState})
  },
  setLogout: (store) => {
    //reset UI state
    store.setState({blade: initialState.blade});
    store.setState({alert: initialState.alert});
    store.setState({userInfo: initialState.userInfo})
    store.setState({authenticated: false});
    store.setState({organization: initialState.organization});
    store.setState({organizations: []});
    store.setState({reload: 0});
  },
  setAuthenticated: (store, userInfo) => {
    store.setState({authenticated: true});
    store.setState({userInfo: userInfo});
  },
  setOrganization: (store, org) => {
    store.setState({organization: org})
  },
  setOrganizations: (store, organizations) => {
    store.setState({organizations:organizations});
  },
  setIntegrations: (store, integrations) => {
    store.setState({integrations:integrations});
  },
  setPortalCustomerMeta: (store, metadata) => {
    if (metadata.meta_key == null) {
      return;
    }
    store.setState({
      portalCustomerMeta: {
        ...store.state.portalCustomerMeta,
        [metadata.meta_key.toLowerCase()]: metadata.meta_value,
      },
    });
  },
  setReload: (store) => {
    store.setState({reload: store.state.reload + 1});
  },
  setCloudAccount: (store, cloud_type_id, account_id) => {
    store.setState({cloud_type_id: cloud_type_id, account_id: account_id});
  },
  setDate: (store, date) => {
    store.setState({date: date});
  },
  setHasPortalAccess: (store, hasPortal) => {
    store.setState({hasPortalAccess: hasPortal});
  },
  setSessionTimeoutTime: (store, time) => {
    store.setState({sessionTimeoutTime: time});
  },
  setCommserveTimeRange: (store, timeRange) => {
    store.setState({commserveTimeRange: timeRange});
  },
  setSuperAdmin: (store, value) => {
    store.setState({superAdmin: value})
  },
  setResourceProperties: (store, properties) => {
    store.setState({resourceProperties: properties})
  }
}

export const appState = globalHook(React, initialState, stateActions);